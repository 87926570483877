import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import AuthenticatedPage from '../Components/AuthenticatedPage';

require('./index.scss');
import 'react-table/react-table.css';

const Dashboard = ({ zones }) => {
  return (
    <AuthenticatedPage>
      <div className="page-title">Your Operating Zones</div>
      <ReactTable
        className="-striped -highlight users_table"
        data={zones}
        columns={[
          {
            Header: 'id',
            accessor: 'id',
            maxWidth: 50,
          },
          {
            Header: 'Name',
            accessor: 'name',
            maxWidth: 100,
          },
          {
            Header: 'Messages',
            columns: [
              {
                Header: 'Start Ride',
                accessor: 'start_ride_text',
                className: 'wrap-text',
                maxWidth: 500,
                // eslint-disable-next-line react/prop-types
                Cell: ({ row: { start_ride_text } }) => (
                  <span dangerouslySetInnerHTML={{ __html: start_ride_text }} />
                ),
              },
              {
                Header: 'End Ride',
                accessor: 'end_ride_text',
                className: 'wrap-text',
                maxWidth: 500,
                // eslint-disable-next-line react/prop-types
                Cell: ({ row: { end_ride_text } }) => (
                  <span dangerouslySetInnerHTML={{ __html: end_ride_text }} />
                ),
              },
            ],
          },
        ]}
        defaultSorted={[
          {
            id: 'name',
            desc: false,
          },
        ]}
      />
    </AuthenticatedPage>
  );
};

Dashboard.propTypes = {
  zones: PropTypes.array,
};

const mapStateToProps = (state) => {
  const {
    zones: { zones },
  } = state;
  return { zones };
};

export default connect(mapStateToProps)(Dashboard);
